/*
 * @Author: yusha
 * @Date: 2024-01-08 15:51:07
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-10 14:20:08
 * @Description: 商详-底部按钮
 */

import { memo, useCallback, useContext } from 'react';
import { XStack, Image, Text } from '@snifftest/tamagui';
import { useAtom, useAtomValue } from 'jotai';
import { useRequest } from 'ahooks';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import * as SDK from '@snifftest/sdk/lib/rn';
import { TouchableOpacity } from 'react-native';
import { BORDER_WIDTH, isAndroid } from '@/outs/utils/tool/utils';
import { Badge } from '@/outs/components/Badge';
import { request } from '@/config/request/interceptors';
import { TaEvent } from '@/outs/utils/TDAnalytics';
import { tool } from '@/outs/utils/tool';
import { useNavigationWebViewH5 } from '@/outs/hooks/common/useNavigationWebViewH5';
import { AddProductFavoriteReqDTO } from '@/outs/service/easyGoods';
import { usePageFocuEffect } from '@/outs/hooks/common/usePageFocuEffect';
import { CartAddSkuReq } from '@/outs/service/easyOrder';
import { ContextPreView } from '../../context';

const fitSize = SDK.fitSize;

interface FooterProps {
	/** 是否收藏 */
	favoriteFlag: boolean;
	setFavoriteFlag: (val: boolean) => void;
	selectedSkuInfo: CartAddSkuReq[];
}
const Footer = (props: FooterProps) => {
	const navigation = SDK.useNavigate() as any;
	const { favoriteFlag, setFavoriteFlag, selectedSkuInfo } = props;
	const insets = useSafeAreaInsets();
	const {
		product = {},
		setSkuPopupType,
		allSoldout,
		setSoldOutModalVisible,
		showService,
		atomUserDetail,
		toast,
		goToLogin,
		atomCartCount,
		fromPage,
		backClick
	} = useContext(ContextPreView);
	/** 是否来自顺手捎页面 */
	const isFromOnHandList = fromPage === 'onHandList';
	/** 购物车数量 */
	const [cartCount, setCartCount] = useAtom(atomCartCount!);
	const useDetail = useAtomValue(atomUserDetail!);
	const isLogin = !!useDetail?.customerId;
	/** 更新购物车数量  */
	const { runAsync: getCartList } = useRequest(request.easyOrder.cart.list, {
		manual: true
	});
	const { runAsync: postCancelCollect } = useRequest(
		request.easyGoods.favoriteProduct.cancel,
		{
			manual: true
		}
	);
	const { runAsync: postAddCollect } = useRequest(
		request.easyGoods.favoriteProduct.postFavoriteProduct,
		{
			manual: true
		}
	);
	const goToLoginApp = () => {
		navigation.navigate('Login', {
			redirectKey: 'GoodDetail'
		});
	};
	const collectChange = async () => {
		if (!isLogin) {
			if (SDK.isH5()) {
				goToLogin?.();
				return;
			}
			goToLoginApp();
			return;
		}
		if (favoriteFlag) {
			// 取消收藏
			const res = await postCancelCollect({
				productCode: product.productCode
			});
			if (!res.success) {
				return;
			}
			setFavoriteFlag(false);
			if (SDK.isH5()) {
				toast(tool.strings('取消收藏成功'));
				return;
			}
			tool.Toast.success(tool.strings('取消收藏成功'));

			return;
		}
		SDK.TA.track({
			event: TaEvent.PRODUCT_COLLECT,
			properties: {
				product_code: product.productCode,
				product_title_ja: product.productTitle
			}
		});
		// 收藏
		const {
			platformType,
			productCode,
			productMainImg,
			productSellLowestPriceJa,
			productTitle
		} = product;
		const payload: AddProductFavoriteReqDTO = {
			platformType,
			productCode,
			productMainImg,
			productSellPrice: productSellLowestPriceJa,
			productTitle
		};
		const res = await postAddCollect({
			...payload,
			favoriteProductType: 0
		});
		if (!res.success) {
			return;
		}
		setFavoriteFlag(true);
		if (SDK.isH5()) {
			toast(tool.strings('收藏成功'));
			return;
		}
		tool.Toast.success(tool.strings('收藏成功'));
	};
	/** 获取购物车数量 */
	const getCount = useCallback(() => {
		(async function () {
			// 未登录不请求接口
			if (!isLogin) {
				setCartCount(0);
				return;
			}
			// 获取购物车数量
			const data = await getCartList({ useMock: false });
			setCartCount(data.data?.length ?? 0);
		})();
	}, [getCartList, isLogin, setCartCount]);

	// 聚焦时触发
	usePageFocuEffect(getCount);

	const navigationWebViewH5 = useNavigationWebViewH5();
	const style: any = SDK.isH5()
		? {
				position: 'fixed',
				bottom: 0,
				left: 0,
				right: 0
		  }
		: {};
	return (
		<>
			{!isFromOnHandList && (
				<XStack
					style={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#fff',
						justifyContent: 'space-between',
						paddingLeft: fitSize(12),
						paddingTop:
							isAndroid() || SDK.isH5()
								? fitSize(12)
								: fitSize(6),
						paddingRight: fitSize(12),
						paddingBottom:
							isAndroid() || SDK.isH5()
								? fitSize(12)
								: insets.bottom,
						zIndex: 100,
						borderTopWidth: fitSize(2),
						borderTopColor: '#F0F2F5',
						...style
					}}
				>
					<XStack
						justifyContent="space-between"
						alignItems="center"
						flexDirection="row"
						display="flex"
						flex={1}
					>
						<TouchableOpacity
							accessibilityRole="button"
							onPress={() => {
								collectChange();
							}}
						>
							<Image
								style={{
									width: fitSize(22),
									height: fitSize(22)
									// marginRight: fitSize(16)
								}}
								source={{
									uri: favoriteFlag
										? 'https://static-jp.theckb.com/static-asset/easy-app/collect1@3.x.png'
										: 'https://static-jp.theckb.com/static-asset/easy-app/like@3.x.png'
								}}
							/>
						</TouchableOpacity>

						<Badge
							value={cartCount}
							iconStyle={{
								top: fitSize(0),
								right: fitSize(2),
								width: cartCount > 99 ? fitSize(28) : 'auto',
								height: cartCount > 99 ? fitSize(16) : 'auto'
							}}
							max={99}
						>
							<TouchableOpacity
								accessibilityRole="button"
								onPress={() => {
									if (!isLogin) {
										if (SDK.isH5()) {
											goToLogin?.();
											return;
										}
										goToLoginApp();
										return;
									}
									SDK.TA.track({
										event: TaEvent.SHOPPING_CART_CLICK,
										properties: {
											product_code: product.productCode,
											product_title_ja:
												product.productTitle
										}
									});
									if (SDK.isH5()) {
										navigation(
											'/order/shopcart?fromPage=goodsdetail'
										);
										return;
									}
									navigationWebViewH5('/order/shopcart');
								}}
							>
								<Image
									width={fitSize(22)}
									height={fitSize(22)}
									source={{
										uri: 'https://static-jp.theckb.com/static-asset/easy-app/normal@3.x.png'
									}}
								/>
							</TouchableOpacity>
						</Badge>
						<Image
							width={fitSize(22)}
							height={fitSize(22)}
							marginRight={fitSize(12)}
							onPress={() => {
								showService?.();
							}}
							source={{
								uri: 'https://static-jp.theckb.com/static-asset/easy-app/service@3.x.png'
							}}
						/>
					</XStack>
					{allSoldout && (
						<XStack
							style={{
								paddingLeft: fitSize(30),
								paddingRight: fitSize(30),
								height: fitSize(40),
								textAlign: 'center',
								lineHeight: fitSize(40),
								marginLeft: fitSize(23),
								backgroundColor: '#ababab',
								borderRadius: fitSize(24),
								alignItems: 'center'
							}}
							onPress={() => {
								// 只有一日元活动售罄才有点击事件
								if (product.oneBuyFlag === 1) {
									setSoldOutModalVisible?.(true);
								}
							}}
						>
							<Text
								style={{
									fontSize: fitSize(14),
									fontWeight: 'bold',
									color: '#fff'
								}}
							>
								{tool.strings('商品已售罄')}
							</Text>
						</XStack>
					)}

					{!allSoldout && (
						<XStack>
							<TouchableOpacity
								accessibilityRole="button"
								style={{
									paddingTop: fitSize(12),
									paddingRight: fitSize(16),
									paddingBottom: fitSize(11),
									paddingLeft: fitSize(16),
									borderWidth: fitSize(2),
									borderColor: '#CDD2DA',
									borderRadius: fitSize(24),
									backgroundColor: '#fff',
									marginRight: fitSize(8)
								}}
								onPress={() => {
									if (!isLogin) {
										if (SDK.isH5()) {
											goToLogin?.();
											return;
										}
										goToLoginApp();
										return;
									}
									SDK.TA.track({
										event: TaEvent.ADD_SHOPPING_CART,
										properties: {
											product_code: product.productCode,
											product_title_ja:
												product.productTitle
										}
									});
									setSkuPopupType?.('addCart');
								}}
							>
								<Text
									style={{
										fontSize: fitSize(14),
										fontWeight: 'bold'
									}}
								>
									{tool.strings('加入购物车')}
								</Text>
							</TouchableOpacity>
							<TouchableOpacity
								accessibilityRole="button"
								style={{
									paddingTop: fitSize(12),
									paddingRight: fitSize(16),
									paddingBottom: fitSize(11),
									paddingLeft: fitSize(16),
									borderRadius: fitSize(24),
									backgroundColor: '#1C2026'
								}}
								onPress={() => {
									if (!isLogin) {
										if (SDK.isH5()) {
											goToLogin?.();
											return;
										}
										goToLoginApp();
										return;
									}
									SDK.TA.track({
										event: TaEvent.PRODUCT_BUY,
										properties: {
											product_code: product.productCode,
											product_title_ja:
												product.productTitle
										}
									});
									setSkuPopupType?.('buyItNow');
								}}
							>
								<Text
									style={{
										fontSize: fitSize(14),
										fontWeight: 'bold',
										color: '#fff'
									}}
								>
									{tool.strings('立即订购')}
								</Text>
							</TouchableOpacity>
						</XStack>
					)}
				</XStack>
			)}
			{isFromOnHandList && (
				<XStack
					style={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#fff',
						justifyContent: 'space-between',
						paddingLeft: fitSize(12),
						paddingTop:
							isAndroid() || SDK.isH5()
								? fitSize(12)
								: fitSize(6),
						paddingRight: fitSize(12),
						paddingBottom:
							isAndroid() || SDK.isH5()
								? fitSize(24)
								: insets.bottom,
						zIndex: 100,
						borderTopWidth: fitSize(2),
						borderTopColor: '#F0F2F5',
						...style
					}}
				>
					<Text
						style={{
							fontSize: fitSize(14),
							color: '#1C2026',
							fontWeight: 'bold'
						}}
					>
						{tool.strings('已选{{num}}件', {
							num: selectedSkuInfo.length ?? 0
						})}
					</Text>
					<XStack
						style={{
							paddingTop: fitSize(12),
							paddingRight: fitSize(16),
							paddingBottom: fitSize(11),
							paddingLeft: fitSize(16),
							borderRadius: fitSize(24),
							backgroundColor: '#1C2026'
						}}
						onPress={() => {
							backClick(selectedSkuInfo);
						}}
					>
						<Text
							style={{
								fontSize: fitSize(14),
								fontWeight: 'bold',
								color: '#fff'
							}}
						>
							{tool.strings('确认选择')}
						</Text>
					</XStack>
				</XStack>
			)}
		</>
	);
};
export default memo(Footer);
