/*
 * @Author: yusha
 * @Date: 2024-03-06 17:55:28
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-04-11 18:23:17
 * @Description: 分享
 */

import { Text, XStack, YStack } from '@snifftest/tamagui';
import * as SDK from '@snifftest/sdk/lib/rn';
import {
	forwardRef,
	useImperativeHandle,
	useMemo,
	useRef,
	useState
} from 'react';
import Image from '@sniff/react-native-fast-image';
import { CameraRoll } from '@react-native-camera-roll/camera-roll';
import QRCode from 'react-native-qrcode-svg';
import ViewShot from 'react-native-view-shot';
import { ShareDialog, SharePhotoContent } from 'react-native-fbsdk-next';
import NativeShare, { Social } from 'react-native-share';
import RNFS from 'react-native-fs';
import {
	Modal,
	NativeModules,
	PermissionsAndroid,
	Platform,
	Linking
} from 'react-native';
import { FacebookStoriesShareSingleOptions } from 'react-native-share/lib/typescript/src/types';
import { ProductDetailRespDTO } from '@/outs/service/easyGoods';
import { tool } from '@/outs/utils/tool';
import { formatMoney } from '@/outs/utils';
import { ClipboardText, isAndroid } from '@/outs/utils/tool/utils';
import { ActionParams, ENUM_SHARE_TYPE, NEW_MAP_SHARE_LIST } from './utils';

const { NativeUtils } = NativeModules;
const fitSize = SDK.fitSize;

interface ShareProps {
	/** 当前的商品信息 */
	product?: ProductDetailRespDTO;
	/** 是否显示右侧的分享按钮 */
	noShowHeadMenu?: boolean;
	env?: string;
}

export interface wrapperRefProps {
	openModal: () => void;
}

const Share = forwardRef((props: ShareProps, wrapperRef: any) => {
	const { product, noShowHeadMenu, env } = props;
	const [visible, setVisible] = useState(false);
	const viewShotRef = useRef<ViewShot | null>();
	const SHARE_TEXT_CONTENT_OTHER_SHARE = `${env}/goods/detail?productCode=${product?.productCode}`;
	const SHARE_TEXT_CONTENT_LINE_SHARE = `${env}/goods/detail?productCode${encodeURIComponent(
		`=${product?.productCode}`
	)}`;

	const [message, setMessage] = useState('');

	useImperativeHandle(
		wrapperRef,
		() => ({
			openModal: () => {
				setVisible(true);
			}
		}),
		[]
	);
	/** 商品售价计算 */
	const sellprice = useMemo(() => {
		if (!product) {
			return '';
		}
		const min = formatMoney(product.productSellLowestPriceJa);
		const max = formatMoney(product.productSellHighestPriceJa);
		if (min === max) {
			return `${min}`;
		}
		return `${min} - ${max}`;
	}, [product]);

	/** 活动售价计算 */
	const activePrice = useMemo<string>(() => {
		if (!product) {
			return '';
		}
		const min = formatMoney(product.activityInfo?.activityLowestPriceJa);
		const max = formatMoney(product.activityInfo?.activityHighestPriceJa);
		if (min === max) {
			return `${min}`;
		}
		return `${min} - ${max}`;
	}, [product]);

	/** 获取android权限 */
	const getCheckPermissionPromise = () => {
		if (Number(Platform.Version) >= 33) {
			return PermissionsAndroid.check(
				PermissionsAndroid.PERMISSIONS.READ_MEDIA_IMAGES
			);
		}
		return PermissionsAndroid.check(
			PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE
		);
	};

	// const getRequestPermissionPromise = () => {
	// 	if (Number(Platform.Version) >= 33) {
	// 		return PermissionsAndroid.requestMultiple([
	// 			PermissionsAndroid.PERMISSIONS.READ_MEDIA_IMAGES
	// 		]).then(
	// 			(statuses) =>
	// 				statuses[
	// 					PermissionsAndroid.PERMISSIONS.READ_MEDIA_IMAGES
	// 				] === PermissionsAndroid.RESULTS.GRANTED
	// 		);
	// 	}
	// 	return PermissionsAndroid.request(
	// 		PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE
	// 	).then((status) => status === PermissionsAndroid.RESULTS.GRANTED);
	// };

	/** 获取android的图片权限 */
	async function hasAndroidPermission() {
		const hasPermission = await getCheckPermissionPromise();
		if (hasPermission) {
			return true;
		}
	}

	const showToast = (message: string) => {
		setMessage(message);
		setTimeout(() => {
			setMessage('');
		}, 2000);
	};
	/** 保存分享图片 */
	const saveShareImage = async () => {
		if (!viewShotRef.current) {
			return;
		}
		try {
			const uri = await viewShotRef.current.capture();
			await CameraRoll.save(uri);
			showToast(tool.strings('保存成功'));
		} catch (ex) {
			// 如果报错这个也是保存成功。Native module的未知错误。保存成功也会报出这个错误
			if (String(ex) === 'Error: Unknown error from a native module') {
				return showToast(tool.strings('保存成功'));
			}
			showToast(tool.strings('保存失败'));
		}
	};

	const createPic = async () => {
		if (Platform.OS === 'android' && !(await hasAndroidPermission())) {
			// NativeUtils.goToSettingPage();
			// showToast(tool.strings('没有权限'));
			// return;
			const granted = await PermissionsAndroid.request(
				PermissionsAndroid.PERMISSIONS.READ_MEDIA_IMAGES,
				{
					title: tool.strings('是否允许访问图片'),
					message: tool.strings("存储照片需要访问'照片'的权限"),
					buttonNeutral: tool.strings('暂不设置'),
					buttonNegative: tool.strings('不同意'),
					buttonPositive: tool.strings('同意')
				}
			);
			if (granted === PermissionsAndroid.RESULTS.GRANTED) {
				saveShareImage();
			}
		} else {
			saveShareImage();
		}
	};

	/** 生成action使用的参数 */
	const generateActionParams = async () => {
		/** 获取截图路径 */
		const createImagePath = await viewShotRef.current?.capture?.();
		/** 使用fs读取路径生成base64 */
		const base64Res = await RNFS.readFile(
			createImagePath as string,
			'base64'
		);

		const shareImageData = 'data:image/png;base64,' + base64Res;

		const payload: ActionParams = {
			shareImageData,
			SHARE_TEXT_CONTENT_LINE_SHARE,
			SHARE_TEXT_CONTENT_OTHER_SHARE,
			save: createPic
		};
		return payload;
	};

	const clickShareBtn = async (item: any) => {
		/** 获取点击的类型 */
		const clickType = item.appType;

		const payload = await generateActionParams();

		if (clickType === ENUM_SHARE_TYPE.COPY) {
			item?.action?.(payload);
			showToast(tool.strings('链接复制成功，快去分享吧'));
			return;
		}
		if (clickType === ENUM_SHARE_TYPE.SAVE) {
			item?.action?.(payload);
			return;
		}
		const installPackageList = await NativeUtils.getInstallList();
		const isInstall = installPackageList[clickType];
		if (!isInstall) {
			showToast(
				'該当アプリはインストールされていません、他のアプリを試しください。または、リンクをコピーしてください。'
			);
			return;
		}
		item?.action?.(payload);
	};

	const openModal = async () => {
		setVisible(true);
	};
	return (
		<>
			{!noShowHeadMenu ? (
				<XStack
					style={{
						width: fitSize(32),
						height: fitSize(32),
						backgroundColor: '#fff',
						borderRadius: fitSize(16),
						alignItems: 'center',
						justifyContent: 'center'
					}}
					onPress={() => {
						openModal();
					}}
				>
					<Image
						style={{ width: fitSize(16), height: fitSize(16) }}
						resizeMode="cover"
						source={{
							uri: 'https://static-jp.theckb.com/static-asset/easy-app/sharetwo@3.x.png'
						}}
					/>
				</XStack>
			) : null}
			<Modal
				transparent={true}
				visible={visible}
				presentationStyle="overFullScreen"
				onRequestClose={() => {
					setVisible(false);
				}}
				statusBarTranslucent={true}
			>
				<YStack
					style={{
						backgroundColor: 'rgba(0,0,0,0.4)',
						left: 0,
						top: 0,
						bottom: 0,
						right: 0,
						zIndex: 2,
						flex: 1,
						position: 'relative'
					}}
				>
					{message ? (
						<YStack
							style={{
								position: 'absolute',
								top: '50%',
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								zIndex: 100
							}}
						>
							<Text
								style={{
									fontSize: fitSize(15),
									lineHeight: fitSize(20),
									borderRadius: fitSize(5),
									paddingHorizontal: fitSize(10),
									paddingVertical: fitSize(4),
									overflow: 'hidden',
									backgroundColor: '#000',
									color: '#fff',
									textAlign: 'center'
								}}
							>
								{message}
							</Text>
						</YStack>
					) : null}
					<ViewShot
						ref={viewShotRef}
						options={{
							fileName: 'demo',
							format: 'jpg',
							quality: 0.9
						}}
						style={{
							width: fitSize(310),
							height: fitSize(462),
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: [
								{ translateY: fitSize(-330) },
								{ translateX: fitSize(-155) }
							],
							// backgroundColor: '#F5F5F5',
							backgroundColor: '#fff',
							padding: fitSize(16),
							borderRadius: fitSize(12),
							overflow: 'hidden'
						}}
					>
						<YStack style={{ width: '100%', height: '100%' }}>
							<YStack>
								<Image
									source={{ uri: product?.productMainImg }}
									style={{
										width: fitSize(278),
										height: fitSize(278),
										aspectRatio: 1,
										borderRadius: fitSize(8)
									}}
								/>

								<YStack
									padding={fitSize(8)}
									backgroundColor="#fff"
								>
									<Text
										color="#FF5010"
										fontSize={fitSize(16)}
										height={fitSize(24)}
										lineHeight={fitSize(24)}
										fontWeight="600"
										marginBottom={fitSize(4)}
									>
										{activePrice.length > 0
											? activePrice
											: sellprice}
										<Text
											style={{
												fontSize: fitSize(12),
												lineHeight: fitSize(24),
												fontWeight: '400',
												color: '#FF5010',
												marginLeft: fitSize(2)
											}}
										>
											{tool.strings('円')}
										</Text>
									</Text>
									<Text
										color="rgba(0, 0, 0, 0.88)"
										fontSize={fitSize(16)}
										fontWeight="500"
										numberOfLines={1}
									>
										{product?.productTitle}
									</Text>
									<XStack
										style={{
											marginTop: fitSize(24),
											alignItems: 'center',
											justifyContent: 'space-between'
										}}
									>
										<YStack>
											<Image
												source={{
													uri: 'https://static-jp.theckb.com/static-asset/easy-app/share-app-icon.png'
												}}
												resizeMode="contain"
												style={{
													width: fitSize(73),
													height: fitSize(32)
												}}
											/>
											<Text
												color="rgba(0, 0, 0, 0.45)"
												fontSize={fitSize(12)}
												fontWeight="400"
												marginTop={fitSize(4)}
												width={fitSize(200)}
												lineHeight={fitSize(20)}
											>
												{tool.strings(
													'识别图片二维码查看商品详情'
												)}
											</Text>
										</YStack>
										<QRCode
											size={fitSize(60)}
											key={SHARE_TEXT_CONTENT_OTHER_SHARE}
											value={
												SHARE_TEXT_CONTENT_OTHER_SHARE
											}
										/>
									</XStack>
								</YStack>
							</YStack>
						</YStack>
					</ViewShot>
					<YStack
						width="100%"
						height={fitSize(224)}
						backgroundColor="#fff"
						position="absolute"
						bottom={0}
						left={0}
						paddingHorizontal={fitSize(12)}
						paddingVertical={fitSize(16)}
						borderTopLeftRadius={fitSize(12)}
						borderTopRightRadius={fitSize(12)}
						marginTop="auto"
					>
						<XStack width="100%" flexWrap="wrap">
							{NEW_MAP_SHARE_LIST.map((item, index) => {
								return (
									<YStack
										key={item.icon + index}
										width={fitSize(86)}
										justifyContent="center"
										alignItems="center"
										marginBottom={fitSize(8)}
										onPress={() => {
											clickShareBtn(item);
										}}
									>
										<Image
											source={{ uri: item.icon }}
											style={{
												width: fitSize(40),
												height: fitSize(40),
												borderRadius: fitSize(40),
												overflow: 'hidden',
												marginBottom: fitSize(4)
											}}
										/>

										<Text
											color="#000"
											fontSize={fitSize(12)}
											fontWeight="400"
											lineHeight={fitSize(24)}
										>
											{item.title()}
										</Text>
									</YStack>
								);
							})}
						</XStack>
						<XStack
							justifyContent="center"
							alignItems="center"
							onPress={() => setVisible(false)}
							marginTop={fitSize(16)}
						>
							<Text
								textAlign="center"
								fontSize={fitSize(14)}
								color={'#0586FE'}
								lineHeight={fitSize(24)}
								fontWeight={'500'}
							>
								{tool.strings('取消')}
							</Text>
						</XStack>
					</YStack>
				</YStack>
			</Modal>
		</>
	);
});
export default Share;
